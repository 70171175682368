<template>
  <div class="container-fluid">
    <div class="row m-3">
      <!-- Aavatar -->
        <div class="mt-3" style="display: flex;">
          <div style="position: relative;">
            <input v-show="false" v-on:change="onAvatarChange" type="file" ref="fileInput" accept="image/*"/>
            <avatar 
              v-if="avatarSelection != null"
              :src="avatarSelection"
            ></avatar>
            <avatar
              v-else-if="profile.avatarUrl"
              :src="profile.avatarUrl"
              backgroundColor="#e5e5e5"
              color="#0097A7"
              :size="60"
            ></avatar>
            <avatar
              v-else
              :username="profile.fullname"
              backgroundColor="#e5e5e5"
              color="#0097A7"
              :size="60"
            ></avatar>
            <div style="cursor: pointer" @click="updateAvatar()">
              <i
                class="fas fa-edit"
                style="position: absolute; bottom: 0; right: 0; color: green;"
              ></i>
            </div>
          </div>
          <div class="ml-3" style="align-self: center;">
            <div class="text-left">
              <span style="display: block; font-size: large; font-weight: bold;">
                {{ profile.fullname }}
              </span>
              <span style="display: block; font-size: small;">
                {{ profile.companyName }}
              </span>
            </div>
          </div>
      </div>
    </div>
    <!-- Email -->
    <div class="row m-3">
      <div
        class="col-xl-1 col-lg-1 col-md-1 p-0"
        style="text-align: left; align-self: center;"
      >
        <span class="mr-3">{{ $t('userProfile.email') }}</span>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 p-0 text-left" style="flex: 0;">
        <span style="color: blue;">{{ profile.email }}</span>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 p-0 text-left">
        <div style="display: inline-block; text-align: left;">
          <!-- Status -->
          <div class="ml-3 mr-3" style="display: inline-block;">
            <div v-show="profile.activeStatus" style="display: inline-block;">
              <i
                class="fas fa-check mr-2"
                style="color: green; font-size: 25px !important;"
              ></i>
            </div>
            <div v-show="!profile.activeStatus" style="display: inline-block;">
              <i
                class="fas fa-times mr-2"
                style="color: red; font-size: 25px !important;"
              ></i>
            </div>
            <span style="line-height: 1px;">{{
              profile.activeStatus ? $t('userProfile.active') : $t('userProfile.notActive')
            }}</span>
          </div>
        </div>
        <!-- Confirm email -->
        <div v-if="!profile.activeStatus" style="display: inline-block;">
          <span
            class="mr-3"
            style="
              padding: 2px 10px 2px 10px;
              border: 1px solid green;
              border-radius: 5px;
              color: green;
            "
            >{{ $t('userProfile.sendConfirmMail') }}</span
          >
          <span
            style="
              padding: 2px 10px 2px 10px;
              border: 1px solid green;
              border-radius: 5px;
              color: green;
            "
            >{{ $t('userProfile.sendConfirmSms') }}</span
          >
        </div>
      </div>
    </div>
    <!-- Information -->
    <div class="row m-3">
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="row">
        <div
          class="col-xl-3 col-lg-3 col-md-3 p-0"
          style="text-align: left; align-self: center;"
        >
          <span class="mr-2">{{ $t('userProfile.changePassword') }}</span>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 p-0">
          <div style="position: relative;">
            <input
              :type="isShowPassword ? 'text' : 'password'"
              class="form-control"
              :placeholder="$t('userProfile.enterNewPassword')"
              aria-label="position"
              aria-describedby="basic-addon1"
            />
            <div
              v-show="!isShowPassword"
              style="position: absolute; right: 0; top: 20%; right: 5px;"
              @click="isShowPassword = !isShowPassword"
            >
              <i class="fas fa-eye-slash"></i>
            </div>
            <div
              v-show="isShowPassword"
              style="position: absolute; right: 0; top: 20%; right: 5px;"
              @click="isShowPassword = !isShowPassword"
            >
              <i class="fas fa-eye"></i>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="row m-3">
      <!-- Full name -->
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="row">
          <div
            class="col-sm-12 col-xl-3 col-lg-3 col-md-3 p-0"
            style="text-align: left; align-self: center;"
          >
            <span class="mr-2">{{ $t('userProfile.fullName') }}</span>
          </div>
          <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
            <input
              v-model="profile.fullname"
              type="text"
              class="form-control"
              :placeholder="$t('userProfile.lastName')"
              aria-label="position"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
      </div>
      <!-- Signature -->
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 signature-style">
        <div class="row">
          <div
            class="col-xl-3 col-lg-3 col-md-3 p-0"
            style="text-align: left; align-self: center;"
          >
            <span>{{ $t('userProfile.signature') }}</span>
          </div>
          <div class="p-0 input-signature">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('userProfile.uploadSignatureFile')"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Phone -->
    <div class="row m-3">
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="row">
          <div
            class="col-xl-3 col-lg-3 col-md-3 p-0"
            style="text-align: left; align-self: center;"
          >
            <span>{{ $t('userProfile.phone') }}</span>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-8 p-0">
            <input
              v-model="profile.phone"
              type="text"
              class="form-control"
              :placeholder="$t('userProfile.phone')"
            />
          </div>
        </div>
      </div>
      <!-- Signature number -->
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 signature-number-style">
        <div class="row">
          <div
            class="col-xl-3 col-lg-3 col-md-3 p-0"
            style="text-align: left; align-self: center;"
          >
            <span>{{ $t('userProfile.numSignature') }}</span>
          </div>
          <div class="p-0 input-num-signature">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('userProfile.methodNumSignature')"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Company -->
    <div class="row m-3">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <div class="row">
          <div
            class="col-xl-3 col-lg-3 col-md-3 p-0"
            style="text-align: left; align-self: center;"
          >
            <span>{{ $t('userProfile.companyName') }}</span>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-8 p-0">
            <input
              v-model="profile.companyName"
              type="text"
              class="form-control"
              :placeholder="$t('userProfile.companyName')"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 signature-number-style">
        <div class="row">
          <div
            class="col-4 col-sm-3 p-0"
            style="text-align: left; align-self: center;"
          >
            <span>{{ $t('userProfile.allowSms') }}</span>
          </div>
          <div class="col-7 p-0 text-left" style="align-self: center;">
            <i class="fas fa-check-square"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- Position -->
    <div class="row m-3">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <div class="row">
          <div
            class="col-xl-3 col-lg-3 col-md-3 p-0"
            style="text-align: left; align-self: center;"
          >
            <span>{{ $t('userProfile.companyPosition') }}</span>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-8 p-0">
            <input
              v-model="profile.companyPosition"
              type="text"
              class="form-control"
              :placeholder="$t('userProfile.companyPosition')"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 signature-number-style">
        <div class="row">
          <div
            class="col-4 col-sm-3 col-md-3 p-0"
            style="text-align: left;"
          >
            <span>{{ $t('userProfile.allowEmail') }}</span>
          </div>
          <div class="col-7 p-0 text-left">
            <i class="fas fa-check-square"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-3">
      <div class="col-sm-12 col-md-4 col-lg-4 cl-xl-4 p-0">
        <button
          @click="onUpdateUserProfile()"
          class="btn-style btn"
          style="color: #ffffff; display: block; width: 60%;"
        >
          {{ $t('userProfile.update') }}
        </button>
      </div>
    </div>
    <div class="row m-3">
      <div class="col-sm-12 col-md-4 col-lg-4 cl-xl-4 p-0">
        <button
          @click="onLogout()"
          class="btn-style btn"
          style="color: #ffffff; display: block; width: 60%;"
        >
          {{ $t('userProfile.singOut') }}
        </button>
      </div>
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
    <loading-bar :isLoading="isLoading"></loading-bar>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import { mapGetters, mapActions } from "vuex";
import { isCookieEnabled, getCookie } from "tiny-cookie";
import axios from "axios";
import functionUtils from 'utils/functionUtils'
export default {
  components: {
    avatar: Avatar,
  },
  data: () => ({
    isShowPassword: false,
    profile: {
      email: "",
      activeStatus: false,
      lastName: "",
      firstName: "",
      fullname: "",
      phone: "",
      companyName: "",
      companyPosition: "",
      avatarUrl: "",
      groups: [],
    },
    avatarSelection: null,
    avatarSelectionFile: null,
    isLoading: false,
    eventId: null,
    mediaServerToken: null,
    mediaServerDomain: null
  }),
  computed: {
    ...mapGetters(["GET_USER_DETAIL_DATA", "GET_SNACK_BAR", "GET_TOKEN_MEDIA_SERVER_DATA"]),
  },
  watch: {
    GET_USER_DETAIL_DATA: function () {
      let userDetail = this.GET_USER_DETAIL_DATA;
      this.profile.email = userDetail.email;
      this.profile.activeStatus = userDetail.is_active;
      this.profile.lastName = userDetail.last_name;
      this.profile.firstName = userDetail.first_name;
      this.profile.fullname =
        userDetail.last_name + " " + userDetail.first_name;
      this.profile.phone = userDetail.profile.phone;
      this.profile.companyName = userDetail.profile.company_name;
      this.profile.companyPosition = userDetail.profile.company_position;
      this.profile.avatarUrl = userDetail.profile.url_avatar;
      this.profile.groups = userDetail.groups;
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function() {
      this.mediaServerToken = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token;
      this.mediaServerDomain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain;
    }
  },
  methods: {
    /**
     * On avatar change
     */
    onAvatarChange: function (e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      if (!files[0].type.includes('image')) {
        this.ON_SHOW_TOAST({
          message: this.$t("userProfile.fileMustBeImage"),
          styleType: "danger",
        });
      } else {
        this.createImage(files[0]);
        this.avatarSelectionFile = files[0]
      }
    },
    /**
     * Create image
     */
    createImage(file) {
        let reader = new FileReader();
        let vm = this;
        reader.onload = (e) => {
            vm.avatarSelection = e.target.result;
        };
        reader.readAsDataURL(file);
    },
    /**
     * Update avatar
     */
    updateAvatar: function () {
      this.$refs.fileInput.click()
    },
    /**
     * Call request update user profile
     */
    callRequestUpdateUserProfile: function (avatarUrl) {
      // Split full name to get last name and first name
      let fullNameArray = this.profile.fullname.split(" ")
      let firstName = fullNameArray[fullNameArray.length - 1]
      let lastName = ""
      for (let i = 0, size = fullNameArray.length - 1; i < size; i++) {
        lastName += fullNameArray[i] + (i < size - 1 ? ' ' : '')
      }
      // Get user id from router
      let userId = this.$route.params.id;
      // Request data
      let data = {
        is_superuser: false,
        last_name: lastName,
        first_name: firstName,
        email: this.profile.email,
        last_login: new Date(),
        username: this.profile.email,
        is_staff: false,
        is_active: true,
        company_name: this.profile.companyName,
        company_position: this.profile.companyPosition,
        date_joined: new Date(),
        user_permissions: [1],
        privilege: [],
        profile: [
          {
            phone: this.profile.phone,
            gender: 3,
            dob: new Date(),
            url_avatar: avatarUrl,
            country_code: 287,
          },
        ],
        groups: [this.profile.groups[0].id]
      };
      this.UPDATE_USER_PROFILE({ id: userId, ...data })
        .then(
          function () {
            this.isLoading = false;
            this.ON_SHOW_TOAST({
              message: this.$t("common.success"),
              styleType: "success",
            });
            let eventId = sessionStorage.getItem("event_id");
            let filter = {
              params: {
                event: eventId ? eventId : null
              }
            }
            this.SET_USER_DATA(filter);
            this.getUserDetail();
            this.avatarSelection = null;
            this.avatarSelectionFile = null;
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false;
            this.ON_SHOW_TOAST({
              message: this.$t("common.somethingWentWrong"),
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    /**
     * On update user profile
     */
    onUpdateUserProfile: function () {
      if (!functionUtils.isNull(this.avatarSelectionFile)) {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", this.avatarSelectionFile);
        formData.append("directory", "avatars/profile/" + this.eventId);
        axios({
          method: "POST",
          timeout: 6000,
          url: this.mediaServerDomain + "/api/v1/upload/",
          data: formData,
          headers: { Authorization: `JWT ${this.mediaServerToken}` }
        }).then(
            function(res) {
              // Get url avatar from server media after upload
              let avatarUrl = res.data.domain + res.data.url
              this.callRequestUpdateUserProfile(avatarUrl)
            }.bind(this)
          ).catch(
            function() {
              this.isLoading = false;
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger"
              });
            }.bind(this)
          )
      } else {
        this.callRequestUpdateUserProfile(this.profile.avatarUrl)
      }
    },
    /**
     * On log out
     */
    onLogout: function () {
      let token = isCookieEnabled() ? getCookie("token") : null;
      sessionStorage.removeItem('event_id')
      this.LOGOUT(token)
        .then(
          function () {
            this.$router.push({
              name: "checkLogin",
            });
          }.bind(this)
        )
        .catch(
          function () {
            this.$router.push({
              name: "checkLogin",
            });
          }.bind(this)
        );
    },
    /**
     * Get user detail
     */
    getUserDetail: function() {
      let userId = this.$route.params.id;
      let filter = {
        params: {
          event: this.eventId,
          id: userId
        },
      };
      this.GET_USER_DETAIL({ ...filter });
    },
    ...mapActions([
      "GET_USER_DETAIL",
      "LOGOUT",
      "UPDATE_USER_PROFILE",
      "SET_USER_DATA",
      "ON_SHOW_TOAST",
      "GET_TOKEN_MEDIA_SERVER"
    ]),
  },
  mounted() {
    this.eventId = sessionStorage.getItem("event_id");
    this.getUserDetail()
    this.GET_TOKEN_MEDIA_SERVER();
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.btn-style {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
*/
@media (max-width: 576px) {
  .first-name-style, .signature-number-style, .signature-style, .allow {
    margin-top: 15px;
  }
}
@media (max-width: 768px) {
  .input-num-signature {
    width: 100%;
  }
  .input-signature {
    width: 100%;
  }
}
</style>
